import SvgIcon from "@mui/material/SvgIcon";
import {ReactComponent as logo} from './logo.svg';

function HomeIcon(props) {
    return (
      <SvgIcon {...props} viewBox="0 0 512 512" component={logo} />
        
    );
  }

  export default HomeIcon;
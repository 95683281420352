import { gql, useQuery } from "@apollo/client";
import { Avatar, Button, CircularProgress, Grid, Link, Typography } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import GameListItem from "./GameListItem";
import { TeamQuery } from "./__gen/TeamQuery";


const conferenceQuery = gql`
    query TeamQuery($guid: ID!, $year: Int!) {
        team(guid: $guid){
            school
            abbreviation
            color
            alt_color
            mascot
            logos
            conference {
                guid
                name
            }
            games(year: $year) {
                guid
                week
                home {
                    points
                    team {
                        guid
                        school
                    }
                }
                away {
                    points
                    team {
                        guid
                        school
                    }
                }
            }
        }
    }
`

function Team() {
    const { guid } = useParams();
    const [year, _setYear] = useState(2021);

    const { loading, error, data } = useQuery<TeamQuery>(conferenceQuery, { variables: { guid, year } });
    if (loading) return <CircularProgress />;
    if (error) return <Typography variant="h4">Failed to load team details.</Typography>;
    console.log('DATA', data);
    return (
        <Grid container direction="column" alignItems="start" justifyContent="flex-start">
            < Grid item>
                <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
                    < Grid item>
                        <Avatar alt={data.team.school} src={data.team?.logos[1] ?? ''} >{data.team.logos[1] == null ? data.team.school.substring(0, 1) : null}</Avatar>
                    </Grid>
                    < Grid item>
                        <Typography variant="h2">{data.team.school}</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item>
                <Grid container direction="row" alignItems="center" spacing={1}>
                    < Grid item>
                        <Typography variant="subtitle1">Conference:</Typography>
                    </Grid>
                    < Grid item>
                        <Button disabled={!data.team.conference} href={`/conference/${data.team.conference?.guid}`} ><Typography variant="body1">{data.team.conference?.name ?? 'N/A'}</Typography></Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6} lg={3} >
                    {data?.team?.games?.map((game, i) => {

                        return (
                            <Grid key={game.guid} item sx={{ backgroundColor: i % 2 === 0 ? 'background.default' : 'background.paper', borderRadius: 4, padding: 1 }}>
                                <GameListItem guid={game.guid} activeTeamGuid={guid} season={year} />
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>


        </Grid>
    )
}

export default Team;
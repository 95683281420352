import { Suspense, useState } from 'react';
import { Button, CircularProgress, Container, Divider, Grid, IconButton, Link, Paper, Tab, Tabs, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from './main/Main';
import Conference from './conference/Conference';
import Team from './team/Team';
import HomeIcon from './AppIcon';
import About from './main/About';
import Fbs from './main/Fbs';

function App() {
  console.log('ENV', process.env.REACT_APP_API_URL);

  return (
    <Grid container alignItems="stretch" >
      <BrowserRouter>
        <Grid container item direction="column" alignItems="stretch">
          <Paper variant="outlined" sx={{ position: 'static', borderRight: '0', borderLeft: '0', borderTop: '0' }}>
            <Container maxWidth="xl">
              <Toolbar>
                <IconButton color="info" href="/">
                  <HomeIcon />
                </IconButton>
                <Typography variant='h6' sx={{ marginX: 1}}>The Offseason</Typography>
                
                  <Button variant='outlined' href="/fbs" >FBS</Button>
                <div style={{flexGrow: 1}}></div>
                <Link underline="hover" href="/about"><Typography variant="subtitle1">about</Typography></Link>
                <Divider variant='middle' orientation='vertical' sx={{marginX: 1}} flexItem />
                <Link underline="hover" href="/"><Typography variant="subtitle1">profile</Typography></Link>
                {/* <IconButton color="secondary"><MenuIcon /></IconButton> */}
              </Toolbar>
            </Container>
          </Paper>
        </Grid>
        <Container maxWidth="xl" sx={{ padding: 1 }}>
          <Suspense fallback={<CircularProgress />}>
            <Routes>
              <Route path="/" element={<Main />} >
              </Route>
              <Route path="fbs" element={<Fbs />} />
              <Route path="about" element={<About />} />
              <Route path="conference/:guid" element={<Conference />} />
              <Route path="team/:guid" element={<Team />} />

            </Routes>
          </Suspense>
        </Container>
      </BrowserRouter>
    </Grid>
  );
}

export default App;

import { gql, useQuery } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import grey from "@mui/material/colors/grey";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { MainQuery } from "./__gen/MainQuery";

const conferencesQuery = gql`
    query FbsQuery {
        conferences{
            guid
            name
            abbreviation
            teams {
                guid
                school
            }
        }
    }
`

function Fbs() {
    const { loading, error, data } = useQuery/* <FbsQuery> */(conferencesQuery);
    if (loading) return <CircularProgress />;
    if (error) return <Typography variant="h4">Failed to load conferences.</Typography>;
    return (
        <Grid container direction="column" alignItems="start" justifyContent="flex-start" flexGrow={0} >
            <Grid container spacing={1} direction="row" justifyContent="center" alignItems="flex-start" >

                {data?.conferences?.map(conference => (
                    <Grid item key={conference.name} xs={12} md={6} xl={3} flexGrow={1} alignSelf="stretch">
                        <Paper sx={{width: '100%', height: '100%'}} variant="outlined" onClick={()=>{console.log('nav to',`/conference/${conference.guid}`)}}>
                            <Grid container direction="column" alignItems="flex-start" justifyContent="flex-start" padding={1}>
                                <Grid item>
                                    <Link color="primary" underline="hover" href={`/conference/${conference.guid}`}>
                                        <Typography variant="h5">{conference.name}</Typography>
                                    </Link>
                                    {conference.teams.map(team => (
                                        <Grid item key={team.guid} >
                                            <Link color={grey[300]} underline="hover" href={`/team/${team.guid}`}>
                                                <Typography variant="subtitle1">{team.school}</Typography>
                                            </Link>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}

export default Fbs;
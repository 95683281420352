import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

function About() {
    return (
        <Grid container direction="column" alignItems="start" justifyContent="flex-start" >
            <Grid item>
                <Typography variant="h3">What are we here for?</Typography>
            </Grid>
            <Grid item>
                <Typography variant="body1">Data driven nonsense.</Typography>
            </Grid>
            <Grid item>
                <img src="https://64.media.tumblr.com/02bca9ffaddec1687f0cbca348e2367c/f9dbdc905f107cbe-85/s540x810/b79fe28151f918d16db0911d41284e2084dd880a.gifv" />
            </Grid>
        </Grid>
    )
}

export default About;
import { gql, useQuery } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import grey from "@mui/material/colors/grey";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

function Main() {
   
    return (

            <Grid container spacing={1} direction="row" justifyContent="center" alignItems="flex-start" >
                <Grid item>
                    <Typography>There will be some sort of landing page here.</Typography>
                </Grid>
                
            </Grid>

    )
}

export default Main;
import { LinkProps } from "@mui/material/Link";
import { createTheme } from "@mui/material/styles";
import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

const LinkBehavior = React.forwardRef<
  any,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return (<RouterLink ref={ref} to={href} {...other} />);
});

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#0d9acb',
    },
    secondary: {
      main: '#3ea254',
    },
    background: {
      default: '#1c2a39',
      paper: '#17222f',
    },
    error: {
      main: '#e4695e',
    },
    info: {
      main: '#c3e0e0',
    },
    success: {
      main: '#4ba450',
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
});

export default theme;
import { gql, useQuery } from "@apollo/client";
import { Avatar, Grid, Link, Skeleton, Typography } from "@mui/material";
import format from "date-fns/format";
import { GameListItemQuery, GameListItemQuery_game_away, GameListItemQuery_game_home } from "./__gen/GameListItemQuery";


export const gameQuery = gql`
    query GameListItemQuery($guid: ID!) {
        game(guid: $guid){
                guid
                week
                start_date
                home {
                    points
                    team {
                        guid
                        school
                        logos
                    }
                }
                away {
                    points
                    team {
                        guid
                        school
                        logos
                    }
                }
                notes
        }
    }
`

interface Props {
    guid: string;
    activeTeamGuid: string;
    season: number;
}

function GameListItem({ guid, activeTeamGuid, season: number }: Props) {

    const { loading, error, data } = useQuery<GameListItemQuery>(gameQuery, { variables: { guid } });
    if (loading) return (
        <Grid container alignItems="stretch" direction="column" spacing={0} width={300}>
            <Grid item>
                <Skeleton variant="text" />
            </Grid>
            <Grid item>
                <Skeleton variant="text" />
            </Grid>
        </Grid>
    );
    if (error) return <Typography variant="subtitle1" color="warning">Failed to load game details.</Typography>;

    let selectedTeam;
    let opponent;

    if (data.game.away.team.guid == activeTeamGuid) {
        selectedTeam = data.game.away
        opponent = data.game.home
    } else {
        selectedTeam = data.game.home
        opponent = data.game.away
    }

    const winLoss = selectedTeam.points > opponent.points ? 'W' : selectedTeam.points === null || opponent.points === null ? null : 'L';


    return (
        <Grid container direction="column">
            <Grid item>
                {/* <Typography variant="subtitle1">{data.game.week ? `WK ${data.game.week}` : data.game.notes}</Typography> */}
                <Typography color={'text.secondary'} variant="subtitle1">{format(new Date(data.game.start_date), 'E, L/d')}</Typography>
            </Grid>
            <Grid item>
                <Grid container direction="row" alignItems="center" justifyContent="flex-start" columnSpacing={2} >
                    <Grid item>
                        <Grid container direction="column">
                            <Grid item >
                                <TeamRow stats={data.game.away} emphasize={data.game.away.points > data.game.home.points} />
                            </Grid>
                            <Grid item >
                                <TeamRow stats={data.game.home} emphasize={data.game.home.points > data.game.away.points} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item justifyContent="end">
                        {winLoss !== null && <Typography variant="h6" color={winLoss === 'W' ? 'success.main' : 'error.main'}>{winLoss}</Typography>}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

interface TeamProps {
    stats: GameListItemQuery_game_away | GameListItemQuery_game_home,
    emphasize?: boolean
}

function TeamRow({ stats, emphasize = false }: TeamProps) {
    return (
        <Grid container direction="row" alignItems="center" spacing={1}>
            <Grid item >
                <Link color="inherit" underline="hover" href={`/team/${stats.team.guid}`}>
                    <Grid container direction="row" alignItems="center">
                        <Grid item >
                            <Avatar sx={{ width: 24, height: 24, padding: '2px' }} variant="square" src={stats.team.logos[1]} sizes="xs" />
                        </Grid>
                        <Grid item flexGrow={1} >
                            <Typography color={emphasize ? 'default' : 'text.secondary'} variant="body1">{stats.team.school}</Typography>
                        </Grid>
                    </Grid>
                </Link>
            </Grid>
            <Grid item >
                <Typography color={emphasize ? 'default' : 'text.secondary'} variant="body2">{stats.points}</Typography>
            </Grid>
        </Grid>
    )
}

export default GameListItem;
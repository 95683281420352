import { gql, useQuery } from "@apollo/client";
import { Avatar,  Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { ConferenceQuery } from "./__gen/ConferenceQuery";


const conferenceQuery = gql`
    query ConferenceQuery($guid: ID!) {
        conference(guid: $guid){
            name
            teams {
                guid
                school
                logos
                division
                mascot
            }
        }
    }
`

function Conference() {
    const { guid } = useParams();

    const { loading, error, data } = useQuery<ConferenceQuery>(conferenceQuery, { variables: { guid } });
    if (loading) return <CircularProgress />;
    console.log('DATA', data, "ERR?", error);
    if (error) return <Typography color="warning" variant="subtitle2">Failed to load conference details.</Typography>;

    const divisionMap = {};

    data?.conference?.teams?.forEach(team => {
        if (!divisionMap[team.division]) divisionMap[team.division] = [];

        divisionMap[team.division].push(team);
    })

    console.log('DIVISION MAP', divisionMap);

    return (
        <Grid container direction="column" alignItems="start" justifyContent="center" flexGrow={0} spacing={1}>
            <Grid container item direction="row" alignItems="center" justifyContent="center" spacing={1}>
                < Grid item>
                    <Typography variant="h4">{data.conference.name}</Typography>
                </Grid>
            </Grid>
            {Object.keys(divisionMap).map(divisionKey => (
                <Grid key={divisionKey} container item marginBottom={1} direction="column" alignItems="center">
                    {divisionKey && divisionKey !== 'NULL' && <Grid item><Typography variant="overline">{divisionKey}</Typography></Grid>}
                    <Grid container item spacing={{ xs: 1, md: 3 }} direction="row" justifyContent="center" alignItems="center" >
                        {divisionMap[divisionKey].map(team => (
                            <Grid item key={team.school} xs={12} sm={'auto'} >
                                <Button sx={{ width: '100%' }} variant="outlined" href={`/team/${team.guid}`}>
                                    <Grid container direction="column" justifyContent="flex-start" spacing={{ xs: 0, md: 1 }}>
                                        <Grid container item spacing={1} alignItems="center" direction="row">
                                            <Grid item >
                                                <Avatar alt={team.school} variant="square" src={team.logos[1]} />
                                            </Grid>
                                            <Grid item >
                                                <Typography variant="h6">{team.school}</Typography>
                                            </Grid>

                                        </Grid>
                                        <Grid item alignSelf="end" >
                                            <Typography variant="subtitle2">{team.mascot}</Typography>
                                        </Grid>
                                    </Grid>
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            ))}
        </Grid>
    )
}

export default Conference;